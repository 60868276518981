import React, {Component} from 'react';
import Layout from '../components/layout'
class About extends Component {
	render() {
		return (
			<Layout>
				Gatsby .....
			<div className="main-header-container">
				<div className="main-heading">
					<h1 id="about">About</h1>
					<div>
						<p>
							Berätta lite mer vad vi gör.
						</p>
					</div>
				</div>
			</div>
			</Layout>
		);
	}
}
export default About;

